<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3 class="text-capitalize">{{ title }}</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="item.name"
                        prepend-icon="mdi-text-short"
                        label="Name"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="item.description"
                        prepend-icon="mdi-text"
                        label="Description"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-checkbox
                        v-model="item.enabledValue"
                        label="Enabled Value"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveItem"
                rounded
                :loading="loading"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'SettingItemForm',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        originalItem: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        invoiceSettings: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: () => '',
        },
    },
    data: () => ({
        loading: false,
        rules: {
            required: v => !!v || 'The value is required!',
        },
    }),
    computed: {
        itemDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalItem, this.item)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveItem() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                const item = await API.createInvoiceSettingItem({
                    itemType: this.title,
                    settingId: this.invoiceSettings.id,
                    ...this.item,
                })
                this.$emit('addItem', item)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const item = await API.updateInvoiceSettingItem({
                    itemType: this.title,
                    settingId: this.invoiceSettings.id,
                    itemId: this.item.id,
                    ...this.itemDiff,
                })
                this.$emit('replaceItem', item)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteSearch() {
            this.search = ''
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
